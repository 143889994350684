// import { setToken } from '@/utils/auth'

export default {
  data() {
    return {
      permissonIframe: this.$store.getters.permissions || []
    }
  },
  computed: {
    isIframe() {
      return this.$route.path.includes('/iframe')
    }
  },
  created() {
    if (this.isIframe) {
      // setToken(token)
      // this.$store.commit('user/SET_TOKEN', token)
      this.$store.dispatch('app/setLanguage', 'zh')
      window.addEventListener('message', e => {
        if (e.data && e.data.msg === 'routeMeta') {
          console.log('e.data: ', e.data)
          this.permissonIframe = e.data.query?.meta?.permissions || []
          const store = e.data.query?.store
          if (store && this.isJSON(store)) {
            const { userId, name, warehouse } = JSON.parse(store)
            this.$store.commit('user/SET_USER_ID', userId)
            this.$store.commit('user/SET_NAME', name)
            this.$store.commit('user/SET_WAREHOUSE', warehouse)
          }
        }
      })
    }
  },
  methods: {
    isJSON(str) {
      try {
        JSON.parse(str)
        return true
      } catch (e) {
        return false
      }
    }
  }

}
