<template>
  <!-- 库位管理 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('page.status')">
            <el-select v-model="form.state" :placeholder="$t('page.selectPlaceholder')">
              <el-option label="待导出" value="0" />
              <el-option label="导出成功" value="1" />
              <el-option label="导出失败" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button @click="refreshClick()">刷新</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="multipleTable"
      :header-cell-style="{background:'#fafafa'}"
      class="mb-3"
      :data="tableDatas"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="55" align="center">
        <template slot-scope="scope">
          {{ scope.row.No }}
        </template>
      </el-table-column>
      <el-table-column prop="exportName" :label="$t('title.ExportTaskName')" align="center" width="160" />
      <el-table-column prop="requestTime" :label="$t('page.createTime')" align="center" width="160" />
      <el-table-column prop="stateI18" :label="$t('page.status')" align="center" width="100" />
      <el-table-column prop="completionTime" :label="$t('title.ExportCompleTime')" align="center" width="180" />
      <el-table-column prop="errorMsg" :label="$t('title.AbnormalInformation')" align="center" />
      <el-table-column fixed="right" :label="$t('page.operate')" width="80" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.state === 1"
            type="text"
            @click="_downloadUrl(scope.row.id,scope.row.source)"
          >
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        class="pt-4 text-xs-right"
        :current-page="pager.current"
        :page-sizes="[10, 50, 200, 500]"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>
<script>
import mixin from '@/mixin/scm-mixin'
import { ExcelTaskPage, downloadUrl, ExcelRefresh } from '@/api/listSelection'
export default {
  mixins: [mixin],
  data() {
    return {
      showRow: false, // 切换显示input
      tableDatas: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      form: {
      }
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  mounted() {
    this._ExcelTaskPage(this.queryParameter)
  },
  methods: {
    async refreshClick() {
      const { code } = await ExcelRefresh()
      if (code === 0) {
        this.$notify({
          message: '刷新成功',
          type: 'success'
        })
        this._ExcelTaskPage(this.queryParameter)
      }
    },
    // 点击查询获取信息
    queryClick() {
      this._ExcelTaskPage(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick(val) {
      this.form.state = ''
      this.queryClick()
    },

    // 点击下载按钮获文件地址  下载文件
    async _downloadUrl(id, source) {
      const { datas } = await downloadUrl({ id, source })
      window.open(datas, '_blank')
    },
    // 获取入库单列表信息数据
    async _ExcelTaskPage(parameter) {
      let No = 0
      const { datas: { pager, records }} = await ExcelTaskPage(parameter)
      No = pager.current * pager.size - pager.size
      this.tableDatas = records.map(e => { e.No = No += 1; return e })
      this.pager = pager
    },
    //  列表分页方法
    handleSizeChange(val) {
      this.pager.current = 1
      this.pager.size = val
      this._ExcelTaskPage(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._ExcelTaskPage(this.queryParameter)
    }

  }
}
</script>

